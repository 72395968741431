import { useRef, createContext, useEffect } from "react";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

const OpenReplayContext = createContext();

function OpenReplayProvider(props) {
  const trackerRef = useRef(null);

  const startOpenReplayAssist = () => {
    if (trackerRef.current != null) return null;
    trackerRef.current = new Tracker({ projectKey: "Rpi4iPumUXYXxiiUmnS9" });
    trackerRef.current.use(
      trackerAssist({
        callConfirm: "Will you allow the agent to call you?",
        controlConfirm: "Will you allow the agent to control your device?",
        config: {},
        onAgentConnect: () => () => {},
        onCallStart: () => () => {},
        onRemoteControlStart: () => () => {},
      })
    );
    trackerRef.current.start({
      userID: "activate",
    });
  };

  const addEvent = (eventName, msg) => {
    if (typeof trackerRef.current?.event === "function")
      trackerRef.current.event(eventName, msg);
  };

  const addIssue = (issueName, msg) => {
    if (typeof trackerRef.current?.issue === "function")
      trackerRef.current.issue(issueName, msg);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      startOpenReplayAssist();
    }
  }, []);

  return (
    <OpenReplayContext.Provider
      value={{
        startOpenReplayAssist,
        addEvent,
        addIssue,
      }}
    >
      {props.children}
    </OpenReplayContext.Provider>
  );
}

export { OpenReplayProvider, OpenReplayContext };
