import {Button} from '@nextui-org/react';
import axios from 'axios';
import {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {toast} from 'react-toastify';

var axiosReq = axios.create({
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

function RegStep3(props) {
  const {activeScreen, setActiveScreen, handleChangeUserData, userData} = props;

  const [companyData, setCompanyData] = useState(userData);
  //const [cid, setCid] = useState(userData.companyID)
  const [taxNumber, setTaxNumber] = useState(userData.taxNumber);
  //const [cidError, setCidError] = useState(false)
  const [taxError, setTaxError] = useState(false);

  useEffect(() => {
    handleChangeUserData('company', companyData);
    // eslint-disable-next-line
  }, [companyData]);

  const getTaxData = (taxNumber) => {
    if (taxNumber == null || taxNumber === '') return null;

    let data = {
      taxNumber: '',
      name: '',
      shortName: '',
      address: {
        city: '',
        countryCode: 'HU',
        floor: '',
        state: '',
        street: '',
        streetNumber: '',
        telNumber: '',
        zip: '',
      },
    };
    setCompanyData({...companyData, ...data});
    try {
      axiosReq.get(`https://ipanel.barsoft.hu/api/v1/common/tax/${taxNumber}`).then((res) => {
        if (res.data.success) {
          data = {
            taxNumber,
            name: res.data.data.name === '' ? res.data.data.shortName : res.data.data.name,
            shortName: res.data.data.shortName,
            address: {
              city: res.data.data.city,
              countryCode: 'HU',
              floor: res.data.data.floor,
              state: '',
              street: res.data.data.street,
              streetNumber: res.data.data.streetNumber + ' ',
              telNumber: '',
              zip: res.data.data.zip,
            },
          };
          setCompanyData({...companyData, ...data});
          setTaxError(false);
        } else {
          toast.error('Hibás adószám!');
          setCompanyData({...companyData, ...data});
          setTaxError(true);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleNext = () => {
    if (userData.name !== '' && userData.taxNumber !== '') setActiveScreen('reg-step4');
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter') {
      handleNext();
    }
  };

  const handleChange = (e) => {
    let value = formatNumber(e.target.value);
    setTaxError(value.length !== 8);
    if (value.length === 8) getTaxData(value);
    setTaxNumber(value);
  };

  // const handleBlurCID = (e) => setCompanyData({...companyData, companyID: e.target.value})

  // const handleChangeCID = (e) => {
  //     let value = formatNumber(e.target.value)
  //     setCidError(value.length !== 12)
  //     setCid(value)
  // }

  const formatNumber = (input) => replaceAll(input, {_: '', ' ': ''});

  const replaceAll = (str, mapObj) => {
    if (str == null) return null;
    let re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return str.replace(re, function (matched) {
      return mapObj[matched.toLowerCase()];
    });
  };

  if (activeScreen !== 'reg-step3') return null;

  return (
    <div className=' device-login-reg-step w-[90%] gap-2 flex flex-col items-center justify-start h-full'>
      <div className='font-bold text-4xl'>Regisztráció</div>
      <div className='font-medium text-xl'>A továbbiakban szükségünk lesz a céged adataira.</div>

      <InputMask
        mask='99999999'
        name='taxNumber'
        type='text'
        className={`relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-unit-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2 ${
          taxError ? 'error' : ''
        }`}
        value={taxNumber}
        onKeyDown={handleKeyPress}
        onChange={handleChange}
        placeholder='Adószám'
      />
      <div className='w-full text-center my-4'>Az adószám megadását követően automatikusan kitöltjük a többi adatot.</div>
      <div className='w-full flex flex-col gap-2 mb-8'>
        {userData.name !== '' && (
          <>
            <div className='w-full flex flex-row justify-between'>
              <div className='label'>Név:</div>
              <div className='value'>{userData.name}</div>
            </div>
            <div className='w-full flex flex-row justify-between'>
              <div className='label'>Rövid név:</div>
              <div className='value'>{userData.shortName}</div>
            </div>
            <div className='w-full flex flex-row justify-between'>
              <div className='label'>Cím:</div>
              <div className='value'>{`${userData?.address?.zip || ''} ${userData?.address?.city || ''} ${userData?.address?.street || ''} ${userData?.address?.streetNumber || ''}`}</div>
            </div>
          </>
        )}
      </div>
      <div className='w-full flex flex-col gap-2'>
        <Button size='lg' color='primary' onPress={handleNext} isDisabled={!taxError && userData?.name !== '' ? false : true} onClick={handleNext}>
          TOVÁBB
        </Button>
        <Button size='lg' fullWidth onClick={() => setActiveScreen('reg-step2')}>
          Vissza
        </Button>
      </div>
    </div>
  );
}
export default RegStep3;
