import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import axios from 'axios';
import LoadSvg from 'components/LoadSvg-v2';
import config from 'config';
import {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useIntercom} from 'react-use-intercom';
import Activate from './Activate';
import LoginUser from './LoginUser';
import RegStep1 from './RegStep1';
import RegStep2 from './RegStep2';
import RegStep3 from './RegStep3';
import RegStep4 from './RegStep4';
import RegStep5 from './RegStep5';
import RegStep6 from './RegStep6';
import Welcome from './Welcome';

function RegisterDevice(props) {
  const {title, error, requestCode, checkPin, onRemoteLogin} = props;

  const trackerRef = useRef(null);
  const {boot} = useIntercom();

  const [activeScreen, setActiveScreen] = useState('welcome');
  const [initUUID, setInitUUID] = useState(localStorage['initUUID']);
  //const [posToken, setPosToken] = useState(localStorage['pos-token'])
  const [userData, setUserData] = useState({
    accountOwner: {
      firstName: '',
      lastName: '',
      email: '',
      telNumber: '',
    },
    company: {
      taxNumber: '',
      companyID: '',
      name: '',
      shortName: '',
      address: {
        zip: '',
        city: '',
        floor: '',
        state: '',
        street: '',
        telNumber: '',
        countryCode: 'HU',
        streetNumber: '',
      },
    },
    location: {
      name: '',
      description: '',
      address: {
        zip: '',
        city: '',
        floor: '',
        state: '',
        street: '',
        telNumber: '',
        countryCode: 'HU',
        streetNumber: '',
      },
      geolocation: {
        lat: '47.5014668',
        lng: '19.0635207',
      },
    },
    options: {
      devices: {
        createPos: true,
      },
    },
  });

  useEffect(() => {
    if (initUUID != null && initUUID !== 'null') {
      setActiveScreen('reg-step6');
    }
  }, [initUUID]);

  const handleChangeUserData = (field, value) => {
    setUserData({...userData, [field]: value});
  };

  const handleSetInitUUID = (uuid) => {
    setInitUUID(uuid);
    localStorage['initUUID'] = uuid;
  };

  const handleSetRegtoken = (token, apiUrl) => {
    console.log('handleSetRegtoken', {token, apiUrl});
    onRemoteLogin(token, apiUrl, 3);
    //setPosToken(token)
    //localStorage['pos-token'] = token
  };

  const activateDemo = () => {
    axios
      .post('https://ipanel.barsoft.hu/api/v1/auth/generateDemoDeviceToken', {
        brandID: 'xkg0gps7',
        deviceType: 3,
        locationUUID: '11ED43C1D8D26690B71E25CE6111D8F4',
      })
      .then((res) => {
        if (res.data.success) {
          // onLogin(res.data.data.token, res.data.data.apiUrl)
          onRemoteLogin(res.data.data.token, res.data.data.apiUrl, 3);
        } else {
          console.log(res.data);
        }
      });
  };

  const reset = () => {
    window.location.reload();
  };

  // if(posToken != null) return (
  //     <div className="device-login-main">
  //         <BrandSettings checkPin={checkPin} setActiveScreen={setActiveScreen} posToken={posToken}/>
  //     </div>
  // )

  useEffect(() => {
    boot();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      trackerRef.current = new Tracker({projectKey: 'C7Zn1ruMWk33IQqBV9U9'});
      trackerRef.current.use(
        trackerAssist({
          callConfirm: 'Will you allow the agent to call you?',
          controlConfirm: 'Will you allow the agent to control your device?',
          config: {},
          onAgentConnect: () => () => {},
          onCallStart: () => () => {},
          onRemoteControlStart: () => () => {},
        })
      );
      trackerRef.current.start();
    }
  }, []);

  if (activeScreen === '')
    return (
      <div className='device-login-main'>
        <div className='btn' onClick={reset}>
          Tovább
        </div>
      </div>
    );
  return (
    <div className={`device-login-main bg-background text-foreground flex flex-row items-center justify-center relative overflow-hidden w-full h-full `}>
      <div className='refresh-svg' onClick={() => window.location.reload()}>
        <LoadSvg name='refresh' />
      </div>
      <div className='left-side hidden lg:flex'>
        <div
          style={{
            position: 'absolute',
            top: '40px',
            left: '40px',
          }}>
          <svg width='191' height='42' viewBox='0 0 191 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M179.041 41.0737C175.071 41.0737 171.677 39.6643 168.877 36.8548C166.068 34.0549 164.658 30.6607 164.658 26.6913V5.11735H171.85V12.3086H179.041V19.4999H171.849L171.851 26.8329C171.881 28.7568 172.559 30.4308 173.93 31.8017C175.335 33.2063 177.056 33.8824 179.041 33.8824V41.0737Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M146.681 41.0737V19.4999C146.681 15.5687 148.09 12.1937 150.9 9.3842C153.699 6.53656 157.094 5.11735 161.063 5.11735V12.3086C159.078 12.3086 157.372 13.018 155.953 14.4468C154.562 15.8661 153.872 17.5536 153.872 19.4999H161.063V26.6913H153.872V41.0737H146.681Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M128.702 41.0737C124.732 41.0737 121.338 39.6643 118.538 36.8548C115.729 34.0549 114.319 30.6607 114.319 26.6913C114.319 22.76 115.729 19.3849 118.538 16.5755C121.338 13.7278 124.732 12.3086 128.702 12.3086C132.633 12.3086 136.008 13.7278 138.818 16.5755C141.665 19.3849 143.085 22.76 143.085 26.6913C143.085 30.6607 141.665 34.0549 138.818 36.8548C136.008 39.6643 132.633 41.0737 128.702 41.0737ZM128.702 33.8824C130.648 33.8824 132.336 33.1921 133.755 31.8017C135.184 30.3827 135.893 28.676 135.893 26.6913C135.893 24.7449 135.184 23.0572 133.755 21.6382C132.336 20.2094 130.648 19.4999 128.702 19.4999C126.717 19.4999 125.01 20.2094 123.591 21.6382C122.201 23.0572 121.511 24.7449 121.511 26.6913C121.511 28.676 122.201 30.3827 123.591 31.8017C125.01 33.1921 126.717 33.8824 128.702 33.8824Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M63.9805 41.0737V26.6913C63.9805 22.76 65.3899 19.3849 68.1992 16.5755C70.9992 13.7278 74.3935 12.3086 78.3628 12.3086V19.4999C76.3781 19.4999 74.6714 20.2094 73.2523 21.6382C71.862 23.0572 71.1717 24.7449 71.1717 26.6913V41.0737H63.9805Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M46.0024 41.0737C42.033 41.0737 38.6387 39.6643 35.8391 36.8548C33.0295 34.0549 31.6201 30.6607 31.6201 26.6913C31.6201 22.76 33.0295 19.3849 35.8391 16.5755C38.6387 13.7278 42.033 12.3086 46.0024 12.3086C49.9337 12.3086 53.309 13.7278 56.1183 16.5755C58.9661 19.3849 60.3851 22.76 60.3851 26.6913V41.0737H53.1938V39.2232C51.0172 40.46 48.6201 41.0737 46.0024 41.0737ZM46.0024 33.8824C47.949 33.8824 49.6364 33.1921 51.0555 31.8017C52.4843 30.3827 53.1938 28.676 53.1938 26.6913C53.1938 24.7449 52.4843 23.0572 51.0555 21.6382C49.6364 20.2094 47.949 19.4999 46.0024 19.4999C44.0178 19.4999 42.3111 20.2094 40.892 21.6382C39.5017 23.0572 38.8115 24.7449 38.8115 26.6913C38.8115 28.676 39.5017 30.3827 40.892 31.8017C42.3111 33.1921 44.0178 33.8824 46.0024 33.8824Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.2578 40.8785C9.18877 40.457 6.50966 39.0919 4.21893 36.8017C1.41388 33.9976 0 30.6076 0 26.6382V5.06443H7.19135V12.2557H14.2348H14.3825V12.2564C18.2887 12.2913 21.6333 13.7101 24.3984 16.5225C27.2078 19.3318 28.6173 22.7069 28.6173 26.6382C28.6173 30.6076 27.2078 34.002 24.3984 36.8017C22.154 39.0537 19.5278 40.4062 16.5296 40.8541C15.1076 41.0665 13.6816 41.0741 12.2578 40.8785ZM14.2348 19.4468H7.19135V26.6382C7.19135 28.623 7.88159 30.3296 9.27201 31.7486C10.6734 33.1218 12.3552 33.8121 14.3086 33.829C16.2619 33.8121 17.9437 33.1218 19.3453 31.7486C20.7357 30.3296 21.4259 28.623 21.4259 26.6382C21.4259 24.6918 20.7357 23.0043 19.3453 21.5851C17.9262 20.1565 16.2197 19.4468 14.2348 19.4468Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M81.9591 26.6913C81.959 22.76 83.3685 19.3849 86.1779 16.5755C88.9357 13.7707 92.2696 12.3519 96.162 12.3097V12.3086H96.3414H110.523V19.4999H96.3414C94.3566 19.4999 92.6502 20.2094 91.231 21.6382C89.8407 23.0572 89.1503 24.7449 89.1503 26.6913H81.9591ZM96.4024 41.0737H96.3414H81.959V33.8824H96.3414C98.288 33.8826 99.9754 33.1923 101.395 31.8017C102.823 30.3827 103.533 28.676 103.533 26.6913H110.724C110.724 30.6607 109.305 34.0549 106.457 36.8548C103.662 39.6498 100.308 41.0589 96.4024 41.0734V41.0737Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M96.3704 31.0059C95.1719 31.0059 94.1459 30.5934 93.2925 29.769C92.468 28.9156 92.0557 27.8897 92.0557 26.6912C92.0557 25.531 92.468 24.524 93.2925 23.6707C94.1459 22.8461 95.1719 22.4339 96.3704 22.4339C97.5305 22.4339 98.5373 22.8461 99.3909 23.6707C100.215 24.524 100.628 25.531 100.628 26.6912C100.628 27.8897 100.215 28.9156 99.3909 29.769C98.5373 30.5934 97.5305 31.0059 96.3704 31.0059Z'
              fill='white'
            />
            <path
              d='M183.919 9.41823V5.7268H185.79C185.94 5.7268 186.103 5.77015 186.28 5.85685C186.457 5.94021 186.607 6.07026 186.73 6.247C186.857 6.4204 186.92 6.64048 186.92 6.90725C186.92 7.17736 186.855 7.40745 186.725 7.59752C186.595 7.78426 186.437 7.92598 186.25 8.02268C186.066 8.11939 185.893 8.16774 185.73 8.16774H184.379V7.56751H185.48C185.59 7.56751 185.708 7.51249 185.835 7.40244C185.965 7.2924 186.03 7.12734 186.03 6.90725C186.03 6.6805 185.965 6.52711 185.835 6.44707C185.708 6.36704 185.596 6.32703 185.5 6.32703H184.729V9.41823H183.919ZM186.15 7.67755L187.07 9.41823H186.18L185.279 7.67755H186.15ZM185.319 11.6191C184.766 11.6191 184.247 11.5157 183.764 11.309C183.28 11.1022 182.855 10.8154 182.488 10.4486C182.122 10.0818 181.835 9.65665 181.628 9.17313C181.421 8.68961 181.318 8.17108 181.318 7.61753C181.318 7.06398 181.421 6.54545 181.628 6.06193C181.835 5.57841 182.122 5.15324 182.488 4.78643C182.855 4.41962 183.28 4.13285 183.764 3.9261C184.247 3.71935 184.766 3.61598 185.319 3.61598C185.873 3.61598 186.392 3.71935 186.875 3.9261C187.359 4.13285 187.784 4.41962 188.151 4.78643C188.517 5.15324 188.804 5.57841 189.011 6.06193C189.218 6.54545 189.321 7.06398 189.321 7.61753C189.321 8.17108 189.218 8.68961 189.011 9.17313C188.804 9.65665 188.517 10.0818 188.151 10.4486C187.784 10.8154 187.359 11.1022 186.875 11.309C186.392 11.5157 185.873 11.6191 185.319 11.6191ZM185.319 10.6587C185.88 10.6587 186.39 10.522 186.85 10.2485C187.31 9.97511 187.677 9.6083 187.951 9.14812C188.224 8.68794 188.361 8.17775 188.361 7.61753C188.361 7.05731 188.224 6.54711 187.951 6.08693C187.677 5.62676 187.31 5.25995 186.85 4.98651C186.39 4.71307 185.88 4.57635 185.319 4.57635C184.759 4.57635 184.249 4.71307 183.789 4.98651C183.329 5.25995 182.962 5.62676 182.688 6.08693C182.415 6.54711 182.278 7.05731 182.278 7.61753C182.278 8.17775 182.415 8.68794 182.688 9.14812C182.962 9.6083 183.329 9.97511 183.789 10.2485C184.249 10.522 184.759 10.6587 185.319 10.6587Z'
              fill='white'
            />
          </svg>
        </div>
        <img src='https://cdn.okeoke.io/global/barsoft/activateBg.png' alt='posbg' />
        <div className='text-box'>
          <div className='title'>BarSoft - A modern vendéglátós szoftver</div>
          <div className='text'>Probléma esetén írj nekünk a chat felületünkön vagy írj a support@barsoft.hu e-mail címre</div>
        </div>
      </div>
      <div className='right-side !w-[90%] md:!w-1/2'>
        <div className='flexible-top-padding' />
        <Welcome activeScreen={activeScreen} setActiveScreen={setActiveScreen} requestCode={requestCode} activateDemo={activateDemo} />
        <LoginUser activeScreen={activeScreen} setActiveScreen={setActiveScreen} />
        <RegStep1 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.accountOwner} />
        <RegStep2 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.accountOwner} />
        <RegStep3 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.company} />
        <RegStep4 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.location} />
        <RegStep5 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData} setInitUUID={handleSetInitUUID} />
        <RegStep6 activeScreen={activeScreen} setActiveScreen={setActiveScreen} initUUID={initUUID} handleSetRegtoken={handleSetRegtoken} />
        <Activate activeScreen={activeScreen} title={title} error={error} requestCode={requestCode} checkPin={checkPin} />
      </div>
      <div className='pos-version'>{`v${config?.version || ''}`}</div>
    </div>
  );
}
export default RegisterDevice;
