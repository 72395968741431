import ReactCodeInput from 'react-code-input'
import { toast } from 'react-toastify'
import axios from 'axios'

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

function Pin(props) {

  const { username } = props

  const handleChangePin = (pin) => {
  
    if(pin.length === 4) {
      try {
        let body = {
          email: username,
          pinCode: pin
        }
        axiosGlobal.post(`https://ipanel.barsoft.hu/api/v1/auth/generateUserToken`, body).then(res => {
          if(res.data.success) {
            localStorage['pos-token'] = res.data.data.token
            window.location.reload()
          } else {
            toast.error("Hibás pin", { autoClose: 2000, hideProgressBar: false })
          }
        })
        
      } catch (error) {
        console.log(error)
      }
    }
  }

  return(
    <div className="pin-fields">
      <p>Emailben kapott pin kód:</p>
      <ReactCodeInput type="number" fields={4} onChange={handleChangePin} autoFocus={false}/>
    </div>
  )
}
export default Pin