import {useOkeFrame} from '@adamlip/okeoke.client';
import {Button} from '@nextui-org/react';
import LoadSvg from 'components/LoadSvg-v2';
import {useState} from 'react';
import ActivateInstrunctionsModal from 'views/RegisterDevice/ActivateInstrunctionsModal';

function Welcome(props) {
  const {activeScreen, setActiveScreen, requestCode, activateDemo} = props;

  const {sendMessageToWebView, frameType} = useOkeFrame();
  const [isOpen, setIsOpen] = useState(false);
  const exitApp = () => {
    sendMessageToWebView({action: 'exitApp'});
  };

  if (activeScreen !== 'welcome') return null;

  return (
    <div className='hiddenOpacity appear device-login-welcome'>
      <ActivateInstrunctionsModal isOpen={isOpen} setIsOpen={setIsOpen} requestCode={requestCode} />
      <div className='logo'>
        <img src='https://cdn.okeoke.io/global/barsoft/Barsoft%20logo%20logo+text.svg' alt='logo' />
      </div>
      <div className='welcome-message' style={{fontSize: '26px', fontWeight: 'bold'}}>
        Üdvözlünk a BarSoft rendszerében!
      </div>
      <div className='font-bold text-xl mb-6'>Rendelkezel már üzlettel a BarSoft rendszerében (brand)?</div>
      <div className='flex flex-col gap-6 w-full'>
        <Button onPress={() => setIsOpen(true)} className='font-bold' fullWidth color='primary' size='lg'>{`Igen, aktiválom ${
          requestCode != null && requestCode !== '' ? '(' + requestCode + ')' : ''
        }`}</Button>
        <Button color='warning' variant='flat' className='font-bold' size='lg' onClick={() => setActiveScreen('reg-step1')}>
          Még nincs üzletem, regisztrálok
        </Button>
        <Button color='warning' className='font-bold' variant='light' size='lg' onClick={activateDemo}>
          Kipróbálom demo módban
        </Button>
      </div>

      {frameType === 'okeElectron' && (
        <div className='btn exit-btn' onClick={exitApp}>
          <LoadSvg name='exit' />
        </div>
      )}
    </div>
  );
}
export default Welcome;
