import {OkeDeviceProvider, OkeFrameProvider, OrderEngineProvider} from '@adamlip/okeoke.client';
import {NextUIProvider} from '@nextui-org/react';
import {OpenReplayProvider} from 'OpenReplayContext';
import {LanguageProvider} from 'intl/languageContext';
import {ThemeProvider as NextThemesProvider} from 'next-themes';
import {createRoot} from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {IntercomProvider} from 'react-use-intercom';
import RegisterDevice from 'views/RegisterDevice';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './style.css';
import './style.scss';
import {ErrorFallback} from './utils/ErrorHandler';

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OkeFrameProvider>
        <NextUIProvider>
          <NextThemesProvider themes={['dark', 'light', 'barDark']} attribute='class' defaultTheme='dark'>
            <div
              style={{
                height: '100vh',
                width: '100vw',
              }}
              className=''>
              <IntercomProvider
                appId='tjh4a2kd'
                onUnreadCountChange={(amount) => {
                  if (window.Intercom && amount > 0) {
                    window.Intercom('update', {
                      hide_default_launcher: false,
                    });
                  }
                }}>
                <ToastContainer />
                <LanguageProvider app='pos' group='main'>
                  <OkeDeviceProvider prefix='pos' title='Pos' types={[1, 2, 3, 4, 5, 6]} CustomDeviceLogin={RegisterDevice}>
                    <OrderEngineProvider>
                      <OpenReplayProvider>
                        <App />
                      </OpenReplayProvider>
                    </OrderEngineProvider>
                  </OkeDeviceProvider>
                </LanguageProvider>
              </IntercomProvider>
            </div>
          </NextThemesProvider>
        </NextUIProvider>
      </OkeFrameProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
serviceWorkerRegistration.register();
