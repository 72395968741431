import {useEffect, useState} from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import NumPad from 'views/RegisterDevice/Numpad';

export default function Activate(props) {
  const {title, error: propError, requestCode, checkPin, activeScreen} = props;
  const [error, setError] = useState(propError || null);

  useEffect(() => {
    setError(propError);
  }, [propError]);

  const reset = () => {
    localStorage.clear();
    window.location.reload();
  };

  //sendMessageToWebView({ action: 'switchAppMode', data: selectedModeCode })

  if (activeScreen !== 'activate') return null;

  return (
    <div className={`hiddenOpacity appear device-activation-root ${isMobileOnly ? 'isMobile' : isTablet ? 'isTablet' : ''}`}>
      <div className='top-row'>
        <div className='logo'>
          <img src='https://cdn.okeoke.io/global/barsoft/Barsoft%20logo%20logo+text.svg' alt='logo' />
        </div>
        <div className='device-activation-request-code'>
          {requestCode === '' && (
            <div className='' onClick={reset}>
              Mutasd az id-t!
            </div>
          )}
          {requestCode !== '' && (
            <div>
              {title}#{requestCode}
            </div>
          )}
        </div>
        <div className='text'>
          <h3>Ez az eszköz még nincs regisztrálva.</h3>Használatához aktiválás szükséges, amit a keretben megadott azonosító alapján az iPanelen tudsz megtenni. <br />
          <br />
          Amennyiben rendelkezel érvényes aktiváló kóddal, annak beírásával is tovább tudsz lépni.
        </div>
      </div>
      <div className='mid'>
        <NumPad onSubmit={checkPin} className='device-numPad' />
      </div>
      <div className='bottom-row'>{error != null && <div className='device-activation-error'>{error?.message}</div>}</div>
      <div className='qr-container'>
        <div className='label'>Go to iPanel</div>
        <a href='https://ipanel.barsoft.hu/brand/devices' target='blank'>
          go
        </a>
      </div>
    </div>
  );
}
