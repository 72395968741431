import {Button} from '@nextui-org/react';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import GridLoader from 'react-spinners/GridLoader';
import {toast} from 'react-toastify';

var axiosReq = axios.create({
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

function RegStep6(props) {
  const {activeScreen, initUUID, setActiveScreen, handleSetRegtoken} = props;
  const timer = useRef(null);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [error, setError] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    clearInterval(timer.current);
    if (initUUID != null && initUUID !== 'null' && !isExpired) {
      timer.current = setInterval(() => {
        axiosReq.get(`https://ipanel.barsoft.hu/api/v1/brand/init/state/${initUUID}`).then((res) => {
          if (res.data.success) {
            const {data} = res.data;
            console.log({data});
            if (data.isConfirmed) {
              setIsConfirmed(true);
            }
            if (data.isOnline) {
              setIsOnline(true);
              localStorage.removeItem('initUUID');
              localStorage.removeItem('reguserEmail');
              handleSetRegtoken(data.token, data.apiUrl);
            }
            if (data.isExpired) {
              setIsExpired(true);
              localStorage.removeItem('initUUID');
              localStorage.removeItem('reguserEmail');
            }
            if (!data.isOnline) {
              let delayed =
                data?.state?.deploy?.databaseState?.state === 'error' ||
                data?.state?.deploy?.domainState?.state === 'error' ||
                data?.state?.deploy?.proxyState?.state === 'error' ||
                data?.state?.deploy?.applicationState?.state === 'error' ||
                data?.state?.deploy?.defaultsState?.state === 'error';
              setIsDelayed(delayed);
            }
          } else {
            console.log(res.data);
            setError(true);
          }
        });
      }, 2000);
    }
    return () => clearInterval(timer.current);
    // eslint-disable-next-line
  }, [initUUID]);

  const goToLoggedInScreen = () => {
    setActiveScreen('brandSettings');
  };

  const handleReset = () => {
    localStorage.removeItem('initUUID');
    localStorage.removeItem('reguserEmail');
    window.location.reload();
  };

  const cancel = () => {
    try {
      let body = {initUUID};
      axiosReq.post(`https://ipanel.barsoft.hu/api/v1/brand/init/cancel`, body).then((res) => {
        if (res.data.success) {
          toast.success('Megszakítva.');
        } else {
          toast.error('Hiba történt!');
          console.log(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (activeScreen !== 'reg-step6') return null;

  if (error)
    return (
      <div className='device-login-reg-step step6'>
        <div className='welcome-message highlighted'>Hiba történt</div>
        <div className='hiddenOpacity appear welcome-message2'>A folyamatot újra kell kezdeni.</div>
        <div className='hiddenOpacity appear  btn-container'>
          <Button color='primary' size='lg' className='mt-4' onClick={handleReset}>
            Újrakezdés
          </Button>
        </div>
      </div>
    );

  if (isExpired)
    return (
      <div className='device-login-reg-step step6'>
        <div className='welcome-message highlighted'>Regisztráció határideje lejárt</div>
        <div className='hiddenOpacity appear welcome-message2'>A folyamatot újra kell kezdeni.</div>
        <div className='hiddenOpacity appear  btn-container'>
          <Button color='primary' size='lg' className='mt-4' onClick={() => window.location.reload()}>
            Újrakezdés
          </Button>
        </div>
      </div>
    );

  return (
    <div className='device-login-reg-step step6'>
      {!isConfirmed && !isOnline && (
        <>
          <div className='welcome-message highlighted'>Megerősítés szükséges</div>
          <div className='welcome-message2'>Kiküldtük a megerősítéshez szükséges emailt a következő címre:</div>
          <div className='welcome-message email'>{localStorage['reguserEmail']}</div>
          <div className='welcome-message2'>Kérjük nyisd meg az e-mailed és kattints az aktiváló linkre.</div>
          Egy pár pillanat és kész...
          <div className='loading'>
            <GridLoader color='#2E9BD9' />
          </div>
          <div className='cancel-progress'>
            <div className='text'>A folyamatot megszakíthatod az alábbi gomb megnyomásával. Ez esetben a megadott adataidat töröljük a rendszerből.</div>
            <Button color='danger' size='lg' className='mt-8' onClick={cancel}>
              Megszakítás
            </Button>
          </div>
        </>
      )}
      {isDelayed && !isOnline && (
        <>
          <div className='welcome-message highlighted'>Figyelem!</div>
          <div className='welcome-message2'>A folyamat több időt vesz igénybe mint amire számítottunk. Értesítünk e-mailben ({localStorage['reguserEmail']}) a folyamat végéről!</div>
          <div className='welcome-message2'>Az applikációt addig nyugodtan bezárhatod.</div>
          <div className='welcome-message2'>Kérjük szíves türelmedet!</div>
        </>
      )}
      {isConfirmed && !isOnline && (
        <>
          <div className='welcome-message highlighted'>Brand készítés folyamatban</div>
          <div className='hiddenOpacity appear welcome-message2'></div>
          Egy pár pillanat és kész...
          <div className='loading'>
            <GridLoader color='#2E9BD9' />
          </div>
        </>
      )}
      {isConfirmed && isOnline && (
        <>
          <div className='welcome-message highlighted'>Brand elkészült</div>
          <div className='hiddenOpacity appear welcome-message2'>Tovább az eszköz aktiváláshoz</div>
          <div className='hiddenOpacity appear  btn-container'>
            <Button color='success' size='lg' className='mt-8' onClick={goToLoggedInScreen}>
              Eszköz aktiválás
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
export default RegStep6;
