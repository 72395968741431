import {Button, Input} from '@nextui-org/react';
import {useRef, useState} from 'react';
import InputMask from 'react-input-mask';

function RegStep2(props) {
  const {activeScreen, setActiveScreen, handleChangeUserData, userData} = props;

  const [error, setError] = useState(false);
  const [localTelNumber, setLocalTelNumber] = useState(userData.telNumber);

  const lastNameRef = useRef(null);

  const handleBlur = (e) => {
    let value = e.target.value;

    if (e.target.name === 'telNumber') {
      value = formatPhoneNumber(e.target.value);
      setError(value.length !== 12);
    }
    handleChangeUserData('accountOwner', {...userData, [e.target.name]: value});
  };

  const handleNext = () => {
    if (!error && userData.firstName !== '' && userData.lastName !== '' && userData.telNumber !== '') setActiveScreen('reg-step3');
  };

  const replaceAll = (str, mapObj) => {
    if (str == null) return null;
    let re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return str.replace(re, function (matched) {
      return mapObj[matched.toLowerCase()];
    });
  };

  const formatPhoneNumber = (input) => replaceAll(input, {_: '', ' ': ''});

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === 'telNumber') {
      value = formatPhoneNumber(e.target.value);
      setError(value.length !== 12);
    }
    setLocalTelNumber(value);
    if (value.length === 12) handleChangeUserData('accountOwner', {...userData, telNumber: value});
  };

  const handleKeyPress = (e, next) => {
    if (e?.key === 'Enter' && next) {
      handleNext();
    }
    if (e?.key === 'Enter') {
      const active = document.activeElement;
      if (active?.nextElementSibling) {
        console.log('active.nextElementSibling', active.nextElementSibling);
        active.nextElementSibling.focus();
      }
    }
  };

  if (activeScreen !== 'reg-step2') return null;

  return (
    <div className=' device-login-reg-step w-[90%] gap-2 flex flex-col items-center justify-start h-full'>
      <div className='font-bold text-4xl'>Regisztráció</div>
      <div className='font-medium text-xl'>Hogyan szólíthatunk téged és milyen számon tudunk felhívni?</div>
      <Input
        name='firstName'
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        label='Keresztnév'
        defaultValue={userData.firstName}
        placeholder='Keresztnév'
        onBlur={handleBlur}
      />
      <Input
        ref={lastNameRef}
        name='lastName'
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        label='Vezetéknév'
        defaultValue={userData.lastName}
        placeholder='Vezetéknév'
        onBlur={handleBlur}
      />
      <InputMask
        mask='+36 99 999 9999'
        name='telNumber'
        type='text'
        className={`textField-input ${
          localTelNumber !== '' && error ? 'error' : ''
        } relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-unit-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2`}
        value={localTelNumber}
        onKeyDown={(e) => {
          handleKeyPress(e, true);
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='Telefonszám'
      />
      <div className='w-full text-center my-4'>Az itt megadott adatok mind fontosak lesznek a fiók létrehozásában.</div>
      <div className='w-full flex flex-col gap-2'>
        <Button
          fullWidth
          size='lg'
          color='primary'
          onPress={handleNext}
          isDisabled={!error && userData.firstName !== '' && userData.lastName !== '' && userData.telNumber !== '' ? false : true}
          onClick={handleNext}>
          TOVÁBB
        </Button>
        <Button size='lg' onClick={() => setActiveScreen('reg-step1')} fullWidth>
          Vissza
        </Button>
      </div>
    </div>
  );
}
export default RegStep2;
