import axios from 'axios';
import {useState} from 'react';
import {toast} from 'react-toastify';
import Pin from './Pin';

var axiosGlobal = axios.create({
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

function LoginUser(props) {
  const {activeScreen, setActiveScreen} = props;
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  const handleBlur = (e) => {
    let emailValid = validateEmail(e.target.value);
    if (emailValid) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const generatePin = () => {
    if (error || email === '') return null;
    try {
      let body = {
        email,
      };
      axiosGlobal.post(`https://ipanel.barsoft.hu/api/v1/auth/generateUserPin`, body).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          toast.success('PIN elküldve!');
        } else {
          toast.error('Hiba történt', {autoClose: 2000, hideProgressBar: false});
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter') {
      generatePin();
    }
  };

  if (activeScreen !== 'loginUser') return null;

  return (
    <div className='hiddenOpacity appear device-login-reg-step login-user'>
      <div className='welcome-message'>Belépés</div>
      <div className='welcome-message2'>Add meg az email címed, amire kiküldjük a belépéshez szükséges pin kódot.</div>
      <input autoFocus className={`${error ? 'error' : ''}`} placeholder='valaki@gmail.com' value={email} onKeyDown={handleKeyPress} onBlur={handleBlur} onChange={(e) => setEmail(e.target.value)} />
      <div className='btn-container'>
        <div className={`btn highlighted ${error || email === '' ? 'disabled' : ''}`} onClick={generatePin}>
          Küldés
        </div>
        <div className='btn' onClick={() => setActiveScreen('welcome')}>
          Vissza
        </div>
      </div>
      <Pin username={email} />
    </div>
  );
}
export default LoginUser;
