import {Button, Checkbox, Link, cn} from '@nextui-org/react';
import axios from 'axios';
import useMultilang from 'intl/useMultilang';
import {useState} from 'react';
import {toast} from 'react-toastify';

var axiosReq = axios.create({
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

function RegStep5(props) {
  const {activeScreen, setActiveScreen, userData, setInitUUID} = props;

  const {getT} = useMultilang();

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);

  const submit = () => {
    if (!agreeToTerms || !agreeToPrivacy) {
      toast.error('Folytatáshoz el kell fogadnod az ÁSZF-et és az adatvédelmi szabályzatot');
      return null;
    }
    let body = userData;
    axiosReq.post(`https://ipanel.barsoft.hu/api/v1/brand/init/submit`, body).then((res) => {
      if (res.data.success) {
        setInitUUID(res.data.data.initUUID);
        setActiveScreen('reg-step6');
      } else {
        for (let i of res?.data?.data?.errors) {
          toast.error(getT(i?.errorMsgCode));
        }
        console.log(res.data);
      }
    });
  };

  if (activeScreen !== 'reg-step5') return null;

  return (
    <div className=' device-login-reg-step w-[90%] gap-2 flex flex-col items-center justify-start h-full'>
      <div className='font-bold text-4xl'>Regisztráció</div>
      <div className='font-medium text-lg mb-10 text-center'>Kérjük ellenőrizd le, hogy az általad megadott adatok helyesek-e.</div>
      <div className='flex flex-row justify-between items-center w-full'>
        <div className='label'>E-mail cím:</div>
        <div className='value'>{userData.accountOwner.email}</div>
      </div>
      <div className='flex flex-row justify-between items-center w-full'>
        <div className='label'>Teljes név:</div>
        <div className='value'>{`${userData.accountOwner.lastName} ${userData.accountOwner.firstName}`}</div>
      </div>
      <div className='flex flex-row justify-between items-center w-full'>
        <div className='label'>Üzlet cím:</div>
        <div className='value'>{`${userData.location.address.zip}, ${userData.location.address.city}, ${userData.location.address.street} ${userData.location.address.streetNumber}.`}</div>
      </div>
      <div className='w-full flex flex-col gap-6 items-center justify-center my-6'>
        <>
          <Checkbox
            classNames={{
              base: cn(
                'inline-flex w-full max-w-md bg-content1',
                'hover:bg-content2 items-center justify-start',
                'cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent',
                'data-[selected=true]:border-primary'
              ),
              label: 'w-full',
            }}
            className={`row flex flex-row gap-2`}
            isSelected={agreeToTerms}
            onValueChange={(value) => setAgreeToTerms(value)}>
            <div className=''>Elfogadom az ÁSZF-et</div>
          </Checkbox>
        </>

        <Checkbox
          classNames={{
            base: cn(
              'inline-flex w-full max-w-md bg-content1',
              'hover:bg-content2 items-center justify-start',
              'cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent',
              'data-[selected=true]:border-primary'
            ),
            label: 'w-full',
          }}
          className={`row flex flex-row gap-2`}
          isSelected={agreeToPrivacy}
          onValueChange={(value) => setAgreeToPrivacy(value)}>
          <div className=''>Elfogadom az Adatvédelmi szabályatot</div>
        </Checkbox>
      </div>
      <div className='hiddenOpacity appear terms-and-conditions-container'>
        <Button as={Link} color='warning' variant='flat' href={'https://docs.barsoft.hu/assets/pdf/aszf.pdf'} target={'_blanks'}>
          Általános szerződési feltételek
        </Button>
        <Button as={Link} color='warning' variant='flat' href={'https://docs.barsoft.hu/assets/pdf/gdpr.pdf'} target={'_blanks'}>
          Adatvédelmi szabályat
        </Button>
      </div>
      <div className='w-full flex flex-col gap-2'>
        <Button size='lg' onPress={submit} fullWidth isDisabled={agreeToPrivacy && agreeToTerms ? false : true} color='primary' onClick={submit}>
          MEGERŐSÍTEM
        </Button>
        <Button size='lg' fullWidth onClick={() => setActiveScreen('reg-step4')}>
          Vissza
        </Button>
      </div>
    </div>
  );
}
export default RegStep5;
