import {Button, Divider, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
function ActivateInstrunctionsModal({isOpen, setIsOpen, requestCode}) {
  return (
    <Modal isOpen={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>Eszköz aktiválása</ModalHeader>
            <ModalBody>
              <p>Amennyiben már rendelkezel üzlettel a BarSoft rendszerében, akkor az eszköz aktiválásához kövesd a következő lépéseket:</p>
              <Divider className='mt-2' />

              <p className='font-semibold'>1. Nyisd meg a BarSoft iPanel rendszerét ( barsoft.hu/ipanel ) és jelentkezz be a felhasználói fiókodba.</p>
              <p className='font-semibold'>2. A menüben válaszd ki az Eszközök menüpontot és azon belül is a POS eszközöket</p>
              <p className='font-semibold'>3. A meglévő eszközeid közül válaszd ki azt amelyiket használni szeretnéd majd kattitns az "Aktiválás" gombra.</p>
              <p className='font-semibold'>Írd be az aktivációs kódot ami a következő: </p>
              <p className='font-bold text-2xl text-primary w-full text-center'>{requestCode || ''} </p>
            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='light' onPress={onClose}>
                Bezárás
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ActivateInstrunctionsModal;
