import {Button, Input} from '@nextui-org/react';
import {useState} from 'react';

function RegStep1(props) {
  const {activeScreen, setActiveScreen, handleChangeUserData, userData} = props;

  const [error, setError] = useState(false);
  const [reguserEmail, setReguserEmail] = useState('');

  const handleChange = (e) => {
    console.log(e.target.value);
    e.target.value = e.target.value?.toLowerCase();
    let emailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value);
    if (emailValid) {
      setError(false);
      setReguserEmail(e.target.value);
    } else {
      setError(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === 'Enter') {
      handleNext();
    }
  };

  const handleNext = () => {
    if (!error && reguserEmail !== '') {
      localStorage['reguserEmail'] = reguserEmail;
      handleChangeUserData('accountOwner', {...userData, email: reguserEmail});
      setActiveScreen('reg-step2');
    }
  };

  if (activeScreen !== 'reg-step1') return null;
  return (
    <div className='w-[90%] gap-2 flex flex-col items-center justify-start h-full'>
      <div className='font-bold text-4xl'>Regisztráció</div>
      <div className='font-medium text-xl'>Elsőnek kérlek add meg az e-mail címedet</div>
      <Input type='email' placeholder='valaki@gmail.com' onKeyDown={handleKeyPress} onBlur={handleChange} onChange={handleChange} defaultValue={userData.email} label='E-mail cím' name='email' />
      <div className='text-center w-full my-5'>Az itt megadott e-mail-címre fogjuk küldeni az aktiváló e-mail-t, frissítéseket és egyéb fontos információkat.</div>
      <div className='flex flex-col gap-2 w-full'>
        <Button size='lg' color='primary' isDisabled={reguserEmail?.email === '' || error === true ? true : false} onClick={handleNext} onPress={handleNext} fullWidth>
          TOVÁBB
        </Button>
        <Button size='lg' onClick={() => setActiveScreen('welcome')} fullWidth>
          Vissza
        </Button>
      </div>
    </div>
  );
}
export default RegStep1;
