import {Button, Input} from '@nextui-org/react';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import InputMask from 'react-input-mask';

var axiosReq = axios.create({
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

function RegStep4(props) {
  const {activeScreen, setActiveScreen, handleChangeUserData, userData} = props;

  const [localUserData, setLocalUserData] = useState(userData);
  const [error, setError] = useState({});
  const prevZip = useRef('');

  useEffect(() => {
    console.log(localUserData);
    setError({telNumber: formatNumber(localUserData?.address?.telNumber)?.length !== 12, zip: formatNumber(localUserData?.address?.zip).length !== 4});
    if (prevZip.current !== localUserData?.address?.zip) {
      if (formatNumber(localUserData?.address?.zip).length === 4) setZip(localUserData?.address?.zip);
      prevZip.current = localUserData?.address?.zip;
    }
    handleChangeUserData('location', {...localUserData});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUserData]);

  const setZip = (zip) => {
    try {
      axiosReq.get(`https://ipanel.barsoft.hu/api/v1/common/address/region/zip/${zip}`).then((res) => {
        if (res.data.success) {
          let temp = {
            ...localUserData,
            address: {
              ...localUserData?.address,
              zip,
              city: `${res.data.data?.sub_region ? res.data.data?.region + ', ' + res.data.data?.sub_region : res.data.data?.region}`,
            },
          };
          setLocalUserData(temp);
        } else {
          console.log(res);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleBlur = (e) => {};

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === 'name') {
      setLocalUserData({...localUserData, [e.target.name]: value});
    } else {
      setLocalUserData({...localUserData, address: {...localUserData?.address, [e.target.name]: value}});
    }
  };

  const replaceAll = (str, mapObj) => {
    if (str == null) return null;
    let re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    return str.replace(re, function (matched) {
      return mapObj[matched.toLowerCase()];
    });
  };

  const formatNumber = (input) => replaceAll(input, {_: '', ' ': ''});

  const handleNext = () => {
    if (
      !error.telNumber &&
      !error.zip &&
      userData.name !== '' &&
      userData.address.telNumber !== '' &&
      userData.address.city !== '' &&
      userData.address.street !== '' &&
      userData.address.streetNumber !== '' &&
      userData.address.zip !== ''
    ) {
      setActiveScreen('reg-step5');
    }
  };

  const handleKeyPress = (e, next) => {
    if (e?.key === 'Enter' && next) {
      handleNext();
    }
    if (e?.key === 'Enter') {
      const active = document.activeElement;
      if (active?.nextElementSibling) {
        console.log('active.nextElementSibling', active.nextElementSibling);
        active.nextElementSibling.focus();
      }
    }
  };

  if (activeScreen !== 'reg-step4') return null;

  return (
    <div className=' w-[90%] gap-2 flex flex-col items-center justify-start h-full'>
      <div className='font-bold text-4xl'>Regisztráció</div>
      <div className='font-medium text-xl'>Most kérjük add meg az üzleted adatait!</div>
      <Input
        className='hiddenOpacity appear'
        name='name'
        value={localUserData.name || ''}
        placeholder='Üzlet neve'
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <InputMask
        mask='+36 99 999 9999'
        name='telNumber'
        type='text'
        className={`relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-unit-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2 ${
          (localUserData?.address?.telNumber || '') !== '' && error.telNumber ? 'error' : ''
        }`}
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        value={localUserData?.address?.telNumber || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='Telefonszám'
      />
      <InputMask
        mask='9999'
        name='zip'
        type='text'
        className={`relative w-full inline-flex tap-highlight-transparent shadow-sm px-3 bg-default-100 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 min-h-unit-10 rounded-medium flex-col items-start justify-center gap-0 transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background h-14 py-2 ${
          (localUserData?.address?.zip || '') !== '' && error.zip ? 'error' : ''
        }`}
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        value={localUserData?.address?.zip || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='Irányítószám'
      />
      <Input
        name='city'
        value={localUserData?.address?.city || ''}
        placeholder='Város'
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Input
        name='street'
        value={localUserData?.address?.street || ''}
        placeholder='Utca'
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Input
        name='streetNumber'
        value={localUserData?.address?.streetNumber || ''}
        placeholder='Házszám'
        onKeyDown={(e) => {
          handleKeyPress(e, true);
        }}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <div className='w-full text-center my-4'>Az itt megadott adatok mind fontosak lesznek a fiók létrehozásában.</div>
      <div className='w-full flex flex-col gap-2'>
        <Button
          color='primary'
          fullWidth
          isDisabled={
            !error.telNumber &&
            !error.zip &&
            localUserData.name !== '' &&
            localUserData.address?.telNumber !== '' &&
            localUserData.address?.city !== '' &&
            localUserData.address?.street !== '' &&
            localUserData.address?.streetNumber !== '' &&
            localUserData.address?.zip !== ''
              ? false
              : true
          }
          size='lg'
          onPress={handleNext}
          onClick={handleNext}>
          TOVÁBB
        </Button>
        <Button size='lg' onClick={() => setActiveScreen('reg-step3')} fullWidth>
          Vissza
        </Button>
      </div>
    </div>
  );
}
export default RegStep4;
